<template>
  <div class="user-info">
    <div >
      <img class="user-poster" :src="require('./background1.jpg')">
    </div>
    <div class="user-group">
      <van-form @submit="onSubmit">
        <van-field
            v-model="user.name"
            :readonly="readOnly"
            name="userName"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '必填'}]"
        />
        <van-field
            v-if="readOnly"
            v-model="phone"
            :readonly="readOnly"
            name="phone"
            label="手机号"
            placeholder="用户名"
        />
        <van-field
            v-else
            readonly
            clickable
            name="phone"
            label="手机号"
            :value="phone"
            :rules="[{ validator:validatorPhone,required: true, message: '填入正确的手机号'}]"
            @touchstart.native.stop="show = true"
        />
        <van-number-keyboard
            v-model="phone"
            :show="show"
            :maxlength="11"
            @blur="show = false"
        />
        <van-field name="user.userGroup" label="用户类型">
          <template #input>
            <van-radio-group v-model="user.userGroup" direction="horizontal" :disabled="readOnly">
              <van-radio name="102">检查单位</van-radio>
              <van-radio name="100">企业用户</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div v-if="readOnly">
          <van-field
              v-if="user.userGroup=='100'"
              readonly
              clickable
              name="picker"
              :value="venue.name"
              label="选择企业"
          />
          <van-field
              v-else
              readonly
              clickable
              name="picker"
              :value="office.name"
              label="选择科室"
          />
        </div>
        <div v-else>
          <van-field
              v-if="user.userGroup=='100'"
              readonly
              clickable
              name="picker"
              :value="venue.name"
              @click="showPicker = true"
              label="选择企业"
              placeholder="点击选择企业"
              :rules="[{ validator:validatorInfo,required: true, message: '必填'}]"
          />
          <van-field
              v-else
              readonly
              clickable
              name="picker"
              :value="office.name"
              @click="officePicker = true"
              label="选择科室"
              placeholder="点击选择科室"
              :rules="[{ validator:validatorOffice,required: true, message: '必填'}]"
          />
        </div>

        <div>
          <div style="padding-top: 50px;">
            <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="reviewStatus">
              已经提交申请，尚未通过审核。
            </van-notice-bar>
            <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="submitStatus">
              已经提交申请，请等待审核通过。
            </van-notice-bar>
          </div>


        </div>
<!--        <div v-if="readOnly" class="edit-btn info-btn" @click="readOnly=false" >修改</div>
        <van-button v-else class="info-btn"  size="large"  type="info" native-type="submit" >提交</van-button>-->
      </van-form>
    </div>


    <van-popup v-model="showPicker" position="bottom">
      <van-search v-model="infoSearchkey" placeholder="请输入搜索关键词" />
      <van-picker
          show-toolbar
          :columns="infoList"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="officePicker" position="bottom">
      <van-picker
          show-toolbar
          :columns="officeList"
          value-key="name"
          @confirm="onConfirmOffice"
          @cancel="officePicker = false"
      />
    </van-popup>

  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup,Button ,Form,Field,NumberKeyboard ,Popup,Picker,Notify,Search,RadioGroup, Radio,NoticeBar } from 'vant';
import {OfficeJson, OfficeList} from "@/api/dict";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [NumberKeyboard.name]: NumberKeyboard,
    [Form.name]: Form,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Notify.name]: Notify,
    [Search.name]: Search,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field
  },
  data(){
    return {
      reviewStatus:false,
      submitStatus:false,
      readOnly:true,
      statusStr:"",
      venue:{
        id:"",
        name:""
      },
      office:{
        id:"",
        name:"",
      },
      user:{
        name:"",
        phone:"",
        officeIds:"",
        userGroup:"100",
      },
      phone:"",
      value1:"",
      infoSearchkey:"",
      show:false,
      signShow:false,
      showPicker:false,
      officePicker:false,
      officeList:[],
      officeJson:{},
      infoList:[],
      allInfoList:[],
      errorInfo:"请输入正确的手机号"
    }
  },
  watch:{
    infoSearchkey: {
      handler(n, o) {
        this.listResolve()
      },
      deep: true
    },
  },
  mounted(){
    this.officeJson = OfficeJson;
    this.officeList = OfficeList;
    Promise.all([ this.getCultural()]).then(res=>{
      this.getList();
    })
  },
  methods:{
    getList(){
      console.log(localStorage,"load userInfo")
      this.venue = {
        id:localStorage.getItem("wljptinfoId")?localStorage.getItem("wljptinfoId"):"",
        name:localStorage.getItem("wljptinfoName")?localStorage.getItem("wljptinfoName"):""
      }
      if(localStorage.getItem("reviewStatus")=='0'){
        this.reviewStatus = true;
      }else{
        this.reviewStatus = false;
      }
      let userType = "100";
      if(localStorage.getItem("wljpttagIds")!="100" && localStorage.getItem("wljpttagIds")!="102" ){
        if(localStorage.getItem("wljptofficeIds")){
          userType = "102"
        }
      }else{
        userType = localStorage.getItem("wljpttagIds");
      }
      this.user = {
        name:localStorage.getItem("wljptuserName")?localStorage.getItem("wljptuserName"):"",
        openId:localStorage.getItem("wljptuser"),
        userGroup:userType
      }
      // if(this.user.userGroup)
      if(localStorage.getItem("wljptofficeIds") && this.user.userGroup == GlobalConfig.checkGroup){
        this.office = this.officeJson[localStorage.getItem("wljptofficeIds")];
        // this.user.userGroup = "102"
      }
      console.log(this.user.userGroup,"userGroup")
      this.phone = localStorage.getItem("phone")?localStorage.getItem("phone"):"";

    },
    getCultural(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/layer",
          method: "get"
        }).then(res=>{
          if(res.data.success){
            this.infoList = res.data.data;
            this.allInfoList = res.data.data;
          }
          resolve(true)
        })
      })

    },
    getOffice(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/upm/office/children/320105",
          method: "get",
          headers: {'OAuth': "AnonymousLogin"},
        }).then(res=>{
          if(res.data.success){
            this.officeList = res.data.data;
            res.data.data.map(item=>{
              this.officeJson[item.id] = item;
            })
          }
          console.log(this.officeJson,"kss")
          resolve(true)
        })
      })

    },
    listResolve() {
      let arr = [];
      if (this.infoSearchkey.length > 0) {
        arr = this.allInfoList.filter(item => {
          if (item["name"].indexOf(this.infoSearchkey) > -1) {
            return item
          }
        })
      } else {
        arr = this.allInfoList;
      }
      this.infoList = arr;
    },
    onConfirm(value){
      console.log("value",value);
      this.venue = value;
      this.showPicker = false;
    },
    onConfirmOffice(value){
      this.office = value;
      this.officePicker = false;
    },
    validatorInfo(val,rule){
      if(this.user.userGroup=='100' && !val){
        return false;
      }
      return true;
    },
    validatorOffice(val,rule){
      if(this.user.userGroup=='102' && !val){
        return false;
      }
      return true;
    },
    validatorPhone(val,rule){
      if(!(/^1[3456789]\d{9}$/.test(val))){
        return false;
      }
      return true;
    },
    // 刷新用户
    refreshUser(){
      this.refreshLoading = true;
      this.$http({
        url: '/zuul/cultural/wechat/refreshUsers',
        method: 'get',
      }).then(result => {
      }).finally(f=>{
      })
    },
    // 删除用户分组
    unTagUser(){
      this.$http({
        url: "/zuul/cultural/wechat/tagUsers",
        method: "delete",
        params:{
          openIds:localStorage.getItem("wljptuser"),
          tagId:localStorage.getItem("wljpttagIds")
        }
      }).then(res=>{
        let localProps = Object.keys(localStorage);
        localProps.map(k=>{
          if(k!="user"){
            localStorage.removeItem(k);
          }
        })
        if (result.data.success) {
          this.refreshUser();
        }
      })
    },
    onSubmit(){
      // alert(this.user.openid);
      let data = {
        openid:this.user.openId,
        name:this.user.name,
        phone:this.phone,
      };
      if(this.user.userGroup=='100'){
        data.infoId=this.venue.id;
      }else{
        data.officeIds = this.office.id;
      }
      this.$http({
        url: "/zuul/cultural/user/",
        method: "post",
        data:data
      }).then(res=>{
        this.errorInfo = JSON.stringify(res);
        if(res.data.success){
          Notify({ type: 'success', message: '已提交申请' });
          this.submitStatus = true;
          if(this.submitStatus){
            this.reviewStatus = false;
          }
          if(localStorage.getItem("wljpttagIds")!="0"){
            this.unTagUser();
          }else{
            let localProps = Object.keys(localStorage);
            localProps.map(k=>{
              if(k!="user"){
                localStorage.removeItem(k);
              }
            })
          }

        }
      }).catch(error=>{
        Notify({ type: 'success', message: error });
      })
    }

  }
};
</script>

<style lang="less" scoped>
.edit-btn{
  width: 100%;
  color: #fff;
  background-color: #1989fa;
  border: 1px solid #1989fa;
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  -webkit-appearance: none;
}
.user-poster{
  width: 100%;
  height: 100%;
}
.user-info{
  .info-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
  }

  .user-group{
   /* position: absolute;
    top: 300px;
    //top: 0px;*/
    width: calc(100vw);
    height: calc(100vh - 260px);
    background: white;
    z-index: 9;
  }


}
/*/deep/ .van-button{
  position: fixed;
  bottom: 0px;
  left: 0px;
}*/
</style>
